import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { umsUrl } from '../../../../Containers/Config/index.js';
import { GET_CLIENT_USER, GET_CLIENT_USER_SUCCESS, GET_CLIENT_USER_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getClientUser({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${umsUrl}/admin/v1/organization-admins`,
      method: 'GET',
      headers: {
        'Project-Code': `ADMIN_ACCESS`,
        Authorization: Constant?.CLIENT_TOKEN,
      },
      data: payload?.data,
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      yield put({
        type: GET_CLIENT_USER_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: GET_CLIENT_USER_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CLIENT_USER_ERROR, payload: error });
  }
}

export function* watchgetClientUser() {
  yield takeEvery(GET_CLIENT_USER, getClientUser);
}

function* getClientUserSaga() {
  yield all([fork(watchgetClientUser)]);
}

export default getClientUserSaga;
