import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { Constant } from '../../../../../../../Helpers/constant';
import { formattedWithComma, hasPermission } from '../../../../../../../Helpers/Helpers';
import { PreviewCardHeader, TablePagination } from '../../../../../../../Common';
import DataTable from 'react-data-table-component';
import { PermissionConstants } from '../../../../../../../Helpers/Constants/permissions';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'Name',
          width: '180px',
          selector: row => row?.name,
          wrap: true,
        },
        {
          name: 'Email Id',
          selector: row => row?.email,
          minWidth: '300px',
          wrap: true,
        },
        {
          name: 'Mobile No.',
          selector: row => row?.mobile,
          wrap: true,
          width: '150px',
        },
        {
          name: 'Creation Date',
          selector: row => row?.createdAt,
          width: '200px',
          wrap: true,
        },
      ];

      setColumns(fetchColumns);
      setData(
        props?.data?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;

          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            // bookingId: data?.bookingId || `NA`,
            name: `${data?.firstName} ${data?.lastName}` || ``,
            mobile: data?.mobileNumber || ``,
            email: data?.email || ``,
            createdAt: data?.createdAt ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A') : ``,
          };
        }),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `ACCEPT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `REJECT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `COMPLETE`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{props?.title}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.ADIB_DOWNLOAD) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns}
                data={data}
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(String(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1))}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
