import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, ModalBody, ModalHeader, Input, Label, Col, Row, Spinner } from 'reactstrap';
import { Error, Loader } from '../../../../../../../Common';
import Select from 'react-select';
import { isValidEmail } from '../../../../../../../Helpers/Helpers';
import { CREATE_ADIB_CLIENT, CREATE_ADIB_CLIENT_EMPTY } from '../../../../../../../../store/actions';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const User = ({ onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialValues);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /*  ---------------------------- REDUX STATES ---------------------------- */
  const { createUser, createUserLoading } = useSelector((state: any) => ({
    createUser: state.CreateAdibClient.data,
    createUserLoading: state.CreateAdibClient.loading,
  }));

  useEffect(() => {
    if (createUser !== null) {
      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
      dispatch({
        type: CREATE_ADIB_CLIENT_EMPTY,
      });
    }
  }, [createUser]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'firstName':
        updatedState = {
          ...state,
          firstName: value,
        };
        setState(updatedState);
        break;

      case 'lastName':
        updatedState = {
          ...state,
          lastName: value,
        };
        setState(updatedState);
        break;

      case 'email':
        updatedState = {
          ...state,
          email: value,
        };
        setState(updatedState);
        break;

      default:
      // do nothing
    }
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (flag, data = state) => {
    if (data?.firstName?.trim() !== '' && data?.lastName?.trim() !== '' && data?.email?.trim() && isValidEmail(data?.email?.trim())) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const generateRandomPhoneNumber = () => {
    const prefix = '9'; // Ensure it starts with '9' for a realistic Indian number
    const remainingDigits = Math.floor(Math.random() * 900000000) + 100000000; // Generates a number between 100000000 and 999999999
    return prefix + remainingDigits.toString();
  };

  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*';
    let password = '';
    for (let i = 0; i < 8; i++) {
      // Generates an 8-character password
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };

  const submitHandler = () => {
    const randomPhoneNumber = generateRandomPhoneNumber();
    const randomPassword = generateRandomPassword();

    if (validateErrors(`submissionCheck`)) {
      dispatch({
        type: CREATE_ADIB_CLIENT,
        payload: {
          data: {
            firstName: state.firstName,
            lastName: state.lastName,
            countryCode: '91',
            phoneNumber: randomPhoneNumber,
            email: state.email,
            password: randomPassword,
            uniqueness: 'email',
            username: state.email,
            organizationId: 12,
            workflowId: 37,
          },
        },
      });
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  useEffect(() => {
    if (createUserLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createUserLoading]);

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Create User
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      {loading && <Loader zIndex={true} />}

      <ModalBody>
        <form>
          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="firstName" className="form-label">
                  First Name
                </Label>
                <Input
                  id="firstName"
                  name="firstName"
                  type="text"
                  className="form-control"
                  value={state?.firstName}
                  onChange={inputChangeHandler}
                  placeholder=""
                />
                {isValidationShow && state?.firstName === '' && <Error text="Please enter first name" />}
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="firstName" className="form-label">
                  Last Name
                </Label>
                <Input
                  id="lastName"
                  name="lastName"
                  type="text"
                  className="form-control"
                  value={state?.lastName}
                  onChange={inputChangeHandler}
                  placeholder=""
                />
                {isValidationShow && state?.lastName === '' && <Error text="Please enter last name" />}
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="email" className="form-label">
                  Email
                </Label>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  className="form-control"
                  value={state?.email}
                  onChange={inputChangeHandler}
                  placeholder=""
                />
              </div>
              {isValidationShow && state?.email === '' && <Error text="Please enter email" />}
              {isValidationShow && state?.email?.trim() !== `` && !isValidEmail(state?.email) && <Error text="Please enter correct email" />}{' '}
            </div>
          </Col>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center pt-3">
        <Button className="btn btn-primary" color="primary" onClick={submitHandler}>
          {loading ? (
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            'Submit'
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default User;
