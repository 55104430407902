import React, { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Col, Row } from 'reactstrap';

import HTMLReactParser from 'html-react-parser';
import { Constant } from '../../../../Helpers/constant';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'User Name',
          selector: row => row?.username,
          wrap: true,
          width: '160px',
        },
        {
          name: 'Membership ID',
          selector: row => row?.membershipId,
          wrap: true,
          width: '220px',
        },
        {
          name: 'Partner',
          selector: row => row?.partnerName,
          wrap: true,
          width: '170px',
        },
        {
          name: 'Service',
          selector: row => row?.serviceName,
          wrap: true,
          width: '150px',
        },
        {
          name: 'Booking Date',
          selector: row => row?.bookingDateTime,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Vendor',
          selector: row => row?.vendorName,
          width: '160px',
          wrap: true,
        },
        {
          name: 'Lot',
          selector: row => row?.lotNumber,
          width: '140px',
          wrap: true,
        },
        {
          name: 'Expiry',
          selector: row => row?.expiryDate,
          width: '170px',
          wrap: true,
        },
        {
          name: 'Upload Date',
          selector: row => row?.uploadDate,
          width: '170px',
          wrap: true,
        },
        {
          name: 'Voucher Hash',
          selector: row => row?.voucherHash,
          width: '300px',
          wrap: true,
        },
        {
          name: 'Redemption Process',
          selector: row => <span>{row?.redemptionProcess}</span>,
          width: '400px',
        },
        {
          name: 'Terms and Conditions',
          selector: row => <span>{row?.termsAndCondition}</span>,
          width: '1000px',
          wrap: true,
        },
      ];
      setColumns(fetchColums);

      setData(
        props?.data?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;

          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            username: data?.username || `NA`,
            membershipId: data?.membershipId || `NA`,
            partnerName: data?.partner?.name || `NA`,
            serviceName: data?.service?.name || `NA`,
            bookingDateTime: moment(data?.bookingDateTime).format('DD-MM-YYYY, h:mm A') || `NA`,
            vendorName: data?.vendor?.name || `NA`,
            lotNumber: data?.lotNumber || `NA`,
            expiryDate: moment(data?.expiryDate).format('DD-MM-YYYY, h:mm A') || `NA`,
            uploadDate: moment(data?.uploadDate).format('DD-MM-YYYY, h:mm A') || `NA`,
            voucherHash: data?.voucherHash || `NA`,
            redemptionProcess: HTMLReactParser(data?.redemptionProcess) || `NA`,
            termsAndCondition: HTMLReactParser(data?.termsAndCondition) || `NA`,
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);
  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {/* {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(`${props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1}`)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )} */}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
