import { Dashboard, Login, Home } from './../Containers/Pages/index';

import Logout from '../pages/Authentication/Logout';

/* ---------------------------- SUPPLY STORE ---------------------------- */
import UploadStatus from './../Containers/Pages/SupplyStore/UploadStatus/index';
import AddCategory from '../Containers/Pages/SupplyStore/Category/AddCategory';
import ManageCategory from '../Containers/Pages/SupplyStore/Category/ManageCategory';
import AddLocation from '../Containers/Pages/SupplyStore/Location/AddLocation';
import ManageLocation from '../Containers/Pages/SupplyStore/Location/ManageLocation';
import LocationBlockDateManagement from '../Containers/Pages/SupplyStore/Location/BlockDate';
import AddPartner from '../Containers/Pages/SupplyStore/Partner/AddPartner';
import ManagePartner from '../Containers/Pages/SupplyStore/Partner/ManagePartner';
import AddService from '../Containers/Pages/SupplyStore/Service/AddService';
import ManageService from '../Containers/Pages/SupplyStore/Service/ManageService';

/* ----------------------------------- COMMUNCATION MANAGEMENT ----------------------------------- */
import AddTemplateEngine from '../Containers/Pages/Communication/TemplateEngine/AddTemplateEngine';
import ManageTemplateEngine from '../Containers/Pages/Communication/TemplateEngine/ManageTemplateEngine';

/* ----------------------------------- COUPON MANAGEMENT ----------------------------------- */
// import {
//   StaticCouponManagement,
//   UniqueCouponManagement,
// } from "../Containers/Pages/Coupon";
// import { StaticCouponManagement, UniqueCouponManagement } from "../Containers/Pages/Coupon";
import StaticCouponManagement from './../Containers/Pages/Coupon/Components/StaticCoupon/index';
import UniqueCouponManagement from './../Containers/Pages/Coupon/Components/UniqueCoupon/index';

/* ----------------------------------- CUSTOMER MANAGEMENT ----------------------------------- */
import CustomerManagement from '../Containers/Pages/CustomerManagement';

/* ----------------------------------- CUSTOMER DATA MANAGEMENT ----------------------------------- */
import CustomerDataManagement from '../Containers/Pages/CustomerDataManagement';

/* ----------------------------------- DUPLICATE CARDS MANAGEMENT ----------------------------------- */
import DuplicateCardsManagement from '../Containers/Pages/DuplicateCardsManagement';

/* ----------------------------------- BENEFIT MANAGEMENT ----------------------------------- */
import { BenefitPacksManagement, BenefitGroupsManagement } from './../Containers/Pages/BenefitManagement/index';

/* ----------------------------------- CONFIG STORE MANAGEMENT ----------------------------------- */
import { CatalogueManagement } from './../Containers/Pages/ConfigStore/index';

/* ----------------------------------- BOOKING MANAGEMENT ----------------------------------- */
import GolfBookingManagement from './../Containers/Pages/BookingsManagement/Components/Golf/index';
import UniqueCouponBookingManagement from './../Containers/Pages/BookingsManagement/Components/UniqueCoupon/index';
import StaticCouponBookingManagement from './../Containers/Pages/BookingsManagement/Components/StaticCoupon/index';
import InsuranceBookingManagement from './../Containers/Pages/BookingsManagement/Components/Insurance/index';
import LocalCourierBookingManagement from './../Containers/Pages/BookingsManagement/Components/LocalCourier/index';
import MeetAndGreetBookingManagement from './../Containers/Pages/BookingsManagement/Components/MeetAndGreet/index';
import PickAndDropBookingManagement from './../Containers/Pages/BookingsManagement/Components/PickAndDrop/index';
import QRBookingManagement from './../Containers/Pages/BookingsManagement/Components/QR/index';
import CarServicingBookingManagement from '../Containers/Pages/BookingsManagement/Components/CarServicing';
import CarRegistrationBookingManagement from '../Containers/Pages/BookingsManagement/Components/CarRegistration';
import ThriwePrimeBooking from '../Containers/Pages/BookingsManagement/Components/ThriwePrimeBooking/index';
import DynamicQrBooking from '../Containers/Pages/BookingsManagement/Components/DynamicQr/index';
/* ----------------------------------- DOWNLOAD ----------------------------------- */
import Downloads from '../Containers/Pages/Downloads';

/* ----------------------------------- RULES MANAGEMENT ----------------------------------- */
import { EventManagement, ExpenditureManagement, ServiceWorthManagement } from './../Containers/Pages/RulesManagement/index';
import { CourseWiseFormats, EditCourseFormats, RealTimeSlotsReport, SlotUnavailability } from '../Containers/Pages/GolfAdminRealTime';

/* ----------------------------------- USER MANAGEMENT ----------------------------------- */
import UserManagement from '../Containers/Pages/UserManagement';

/* ----------------------------------- PROJECT MANAGEMENT ----------------------------------- */
import {
  PaymentLogs,
  ProjectPaymentsSettings,
  Clients,
  DataIntegration,
  Program,
  Platform,
  ProjectComs,
  ProgramOnboarding,
} from '../Containers/Pages/ProjectsManagement/index';

import CustomDownload from '../Containers/Pages/CustomDownloadsManagement/CustomDownload';
import { Campaigns, Communication, Events, Ledger, Rules, Wallet } from '../Containers/Pages/SurgeSphere';
import WorkflowManagement from '../Containers/Pages/SupplyStore/Category/Workflow';
import DataPool from '../Containers/Pages/DataPool';
import { DiningBooking, HireADriverBookingManagement, ValetParkingManagement } from '../Containers/Pages/BookingsManagement';
import ServiceBlockDateManagement from '../Containers/Pages/SupplyStore/Service/BlockDate';
import ClubBookingManagement from '../Containers/Pages/BookingsManagement/Components/Club';
import { AirportTransfer } from '../Containers/Pages/InventoryManagement';
import { CommsReport } from '../Containers/Pages/Reports';
import {
  ClientLogin,
  ResetPassword,
  ClientForgotPassword,
  History,
  AirportTransferManualBooking,
  MeetAndGreetManualBooking,
  ContactUs,
  ClientDownloads,
} from '../Containers/Pages/Client';
import { VendorAirportTransfer, VendorDownloads } from '../Containers/Pages/VendorPanel/Enbd';
import { QRPage, VendorBookingValetParking, VendorReport } from '../Containers/Pages/VendorPanel/ValetParking';
import VendorLogin from '../Containers/Pages/VendorPanel/Login';
import VendorPanelDashboard from '../Containers/Pages/VendorPanel/Comman/Dashboard';
import DelayedCouponBookingManagement from '../Containers/Pages/BookingsManagement/Components/DelayedCoupon';
import { GenerateVoucher, ReportLandmark } from '../Containers/Pages/Client/Landmark';
import CarterXBooking from '../Containers/Pages/BookingsManagement/Components/CarterxBooking';
import { VendorDiningBooking } from '../Containers/Pages/VendorPanel/ThriweDining';

/* ----------------------------------- BOOKING MANAGEMENT V2 ----------------------------------- */
import DelayedBookingV2 from '../Containers/Pages/BookingsManagement/Booking_V2/Components/DelayedBooking';
import UniqueCouponV2 from '../Containers/Pages/BookingsManagement/Booking_V2/Components/UniqueCoupon';
import StaticCouponV2 from '../Containers/Pages/BookingsManagement/Booking_V2/Components/StaticCoupon';
import PickAndDropBookingManagementV2 from '../Containers/Pages/BookingsManagement/Booking_V2/Components/PickAndDrop';
import QRBookingManagementV2 from '../Containers/Pages/BookingsManagement/Booking_V2/Components/QR';
import { Feedback, Query } from '../Containers/Pages/Review';
import GolfBookingManagementV2 from '../Containers/Pages/BookingsManagement/Booking_V2/Components/Golf';
import CustomDelayedBookingV2 from '../Containers/Pages/BookingsManagement/Booking_V2/Components/CustomDelayedBooking';

import benefitPackDownloadPermission from '../Containers/Pages/benefitPackDownloadPermission';
import {
  BusinessRegistration,
  Offers,
  Posts,
  Annoucements,
  ClientCustomers,
  ClientGolfBooking,
  ClientUniqueCouponBooking,
  ClientBookingsDownloads,
  UserManagementAdib,
} from '../Containers/Pages/Client/Adib';
import BookingSearch from '../Containers/Pages/BookingSearch';
import { Constant } from '../Containers/Helpers/constant';
import CustomerDownloads from '../Containers/Pages/CustomerDownloads';
/* ----------------------------------- GM REPORT ----------------------------------- */
import DynamicCouponBookingManagement from '../Containers/Pages/BookingsManagement/Components/DynamicCoupon';
import { BookingRecon, ClientPricing, GmReport, MasterUploads, PriceViewer } from '../Containers/Pages/SupplyStore/GmReport';

/* ----------------------------------- INVENTORY SETTINGS ----------------------------------- */
let variable = 'landmark';
const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },

  { path: '/maker-checker', component: benefitPackDownloadPermission },

  // Client routes
  { path: `/client/:clientId/login`, component: ClientLogin },
  { path: `/client/login`, component: ClientLogin },
  { path: '/client/forgot-password', component: ClientForgotPassword },
  { path: '/client/reset-password', component: ResetPassword },

  // Vendor routes
  { path: '/vendor/login', component: VendorLogin },
];

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/', component: Home },

  // Client routes
  { path: '/client/dashboard', component: Dashboard },
  { path: '/client/login', component: Home },

  // LANDMARK CLIENT routes
  { path: `/client/login/:clientId`, component: ClientLogin, name: 'Login' },
  { path: '/client/voucher-generate', component: GenerateVoucher, name: 'GENERATE_VOUCHER' },
  { path: '/client/voucher-bookings', component: ReportLandmark, name: 'VOUCHERS_REPORT' },

  /* ---------------------------- COMMUNICATION ---------------------------- */
  // TEMPLATE ENGINE
  {
    path: '/template-engine/add-template-engine',
    component: AddTemplateEngine,
    name: 'TEMPLATE_ENGINE_CREATE',
  },
  {
    path: '/template-engine/manage-template-engine',
    component: ManageTemplateEngine,
    name: 'TEMPLATE_ENGINE_READ',
  },

  /* ---------------------------- BLOCK DATES MANAGEMENT ---------------------------- */
  {
    path: '/block-dates',
    component: LocationBlockDateManagement,
    name: 'BLOCK_DATE_MANAGEMENT',
  },

  /* ---------------------------- SUPPLY STORE ---------------------------- */
  // CATEGORY
  {
    path: '/category/add-category',
    component: AddCategory,
    name: 'CATEGORY_CREATE',
  },
  {
    path: '/category/manage-category',
    component: ManageCategory,
    name: 'CATEGORY_READ',
  },
  {
    path: '/category/workflow-management',
    component: WorkflowManagement,
    name: 'WORKFLOW_MANAGEMENT',
  },

  // PARTNER
  {
    path: '/partner/add-partner',
    component: AddPartner,
    name: 'PARTNER_CREATE',
  },
  {
    path: '/partner/manage-partner',
    component: ManagePartner,
    name: 'PARTNER_READ',
  },

  // FACILITY
  {
    path: '/facility/add-location',
    component: AddLocation,
    name: `FACILITY_CREATE`,
  },
  {
    path: '/facility/manage-location',
    component: ManageLocation,
    name: `FACILITY_READ`,
  },
  {
    path: '/facility/blocked-date',
    component: LocationBlockDateManagement,
    name: `FACILITY_BLOCKED_DATES`,
  },

  // SERVICE
  {
    path: '/service/add-service',
    component: AddService,
    name: `SERVICE_CREATE`,
  },
  {
    path: '/service/manage-service',
    component: ManageService,
    name: `SERVICE_READ`,
  },
  {
    path: '/service/blocked-date',
    component: ServiceBlockDateManagement,
    name: `SERVICE_BLOCKED_DATES`,
  },

  // UPLOAD STATUS
  {
    path: '/supply-store/upload-status',
    component: UploadStatus,
    name: 'UPLOAD_FILE',
  },

  // MASTER UPLOAD
  {
    path: '/supply-store/master-upload',
    component: MasterUploads,
    name: 'SUPPLY_DATA_GM_REPORT',
  },

  /* ----------------------------------- CONFIG STORE MANAGEMENT ----------------------------------- */
  // BENEFIT CONFIG
  {
    path: '/config-store/benefit-config',
    component: CatalogueManagement,
    name: `BENEFIT_CONFIG`,
  },

  /* ----------------------------------- COUPON MANAGEMENT ----------------------------------- */
  {
    path: '/coupons/unique-coupons',
    component: UniqueCouponManagement,
    name: 'UNQIUE_COUPON',
  },

  {
    path: '/coupons/static-coupons',
    component: StaticCouponManagement,
    name: 'STATIC_COUPON',
  },

  /* ----------------------------------- CUSTOMER MANAGEMENT ----------------------------------- */
  {
    path: '/customer-management',
    component: CustomerManagement,
    name: `CUSTOMER_MANAGEMENT`,
  },

  /* ----------------------------------- CUSTOMER DATA MANAGEMENT ----------------------------------- */
  {
    path: '/customer-data',
    component: CustomerDataManagement,
    name: `CUSTOMER_DATA_MANAGEMENT`,
  },

  /* ----------------------------------- DUPLICATE CARDS MANAGEMENT ----------------------------------- */
  {
    path: '/duplicate-cards',
    component: DuplicateCardsManagement,
    name: `DUPLICATE_CARDS_MANAGEMENT`,
  },

  /* ----------------------------------- SURGE SPHERE MANAGEMENT ----------------------------------- */
  // WALLET
  {
    path: '/surge-sphere/wallet',
    component: Wallet,
    name: `WALLET`,
  },

  // LEDGER
  {
    path: '/surge-sphere/ledger',
    component: Ledger,
    name: `LEDGER`,
  },

  // RULES
  {
    path: '/surge-sphere/rules',
    component: Rules,
    name: `RULES`,
  },

  // CAMPAIGNS
  {
    path: '/surge-sphere/campaigns',
    component: Campaigns,
    name: `CAMPAIGNS`,
  },

  // EVENTS
  {
    path: '/surge-sphere/events',
    component: Events,
    name: `EVENTS`,
  },

  // COMMUNICATION MANAGEMENT
  {
    path: '/surge-sphere/communication',
    component: Communication,
    name: `COMMUNICATION_MANAGEMENT`,
  },

  /* ----------------------------------- CUSTOM REPORT MANAGEMENT ----------------------------------- */
  {
    path: '/custom-reports',
    component: CustomDownload,
    name: `CUSTOM_REPORT_MANAGEMENT`,
  },

  // DATA POOL
  {
    path: '/data-pool',
    component: DataPool,
    name: `DATA_POOL`,
  },

  /* ----------------------------------- BENEFIT MANAGEMENT ----------------------------------- */
  // BENEFIT PACK
  {
    path: '/benefit-pack',
    component: BenefitPacksManagement,
    name: `BENEFIT_PACK`,
  },

  // BENEFIT GROUP
  {
    path: '/benefit-groups',
    component: BenefitGroupsManagement,
    name: `BENEFIT_GROUP`,
  },

  /* ----------------------------------- BOOKING MANAGEMENT ----------------------------------- */
  // STATIC COUPON BOOKING
  {
    path: '/bookings/static-coupon',
    component: StaticCouponBookingManagement,
    name: `STATIC_COUPON_BOOKING_READ`,
  },

  // UNIQUE COUPON BOOKING
  {
    path: '/bookings/unique-coupon',
    component: UniqueCouponBookingManagement,
    name: `UNIQUE_COUPON_BOOKING_READ`,
  },

  // UNIQUE COUPON BOOKING
  {
    path: '/bookings/unique-coupon',
    component: UniqueCouponBookingManagement,
    name: `UNIQUE_COUPON_BOOKING_READ`,
  },

  // DELAYED COUPON BOOKING
  {
    path: '/bookings/delayed-coupon',
    component: DelayedCouponBookingManagement,
    name: `DELAYED_COUPON_BOOKING_READ`,
  },

  // GOLF BOOKING
  {
    path: '/bookings/golf',
    component: GolfBookingManagement,
    name: `GOLF_BOOKING_READ`,
  },

  {
    path: '/vendor/booking/dining',
    component: VendorDiningBooking,
    name: `DINING_BOOKING_MANAGEMENT`,
  },

  // DYNAMIC COUPON BOOKING
  {
    path: '/bookings/dynamic-coupon',
    component: DynamicCouponBookingManagement,
    name: `DYNAMIC_COUPON_BOOKING_READ`,
  },

  // INSURANCE BOOKING
  {
    path: '/bookings/insurance',
    component: InsuranceBookingManagement,
    name: `INSURANCE_BOOKING_READ`,
  },

  // LOCAL COURIER BOOKING
  {
    path: '/bookings/local-courier',
    component: LocalCourierBookingManagement,
    name: `LOCAL_COURIER_BOOKING_READ`,
  },

  // MEET & GREET BOOKING
  {
    path: '/bookings/meet-greet',
    component: MeetAndGreetBookingManagement,
    name: `MEET_AND_GREET_BOOKING_READ`,
  },

  // AIRPORT TRANSFER BOOKING
  {
    path: '/bookings/pick-drop',
    component: PickAndDropBookingManagement,
    name: `PICKUP_AND_DROP_BOOKING_READ`,
  },

  // QR BOOKING
  {
    path: '/bookings/qr',
    component: QRBookingManagement,
    name: `QR_BOOKING_READ`,
  },

  // CARTERX BOOKING
  {
    path: '/bookings/carterx',
    component: CarterXBooking,
    name: `CARTERX_BOOKING_READ`,
  },

  // CAR SERVICING BOOKING
  {
    path: '/bookings/car-servicing',
    component: CarServicingBookingManagement,
    name: `CAR_SERVICING_BOOKING_READ`,
  },

  // CAR REGISTRATION BOOKING
  {
    path: '/bookings/car-registration',
    component: CarRegistrationBookingManagement,
    name: `CAR_REGISTRATION_BOOKING_READ`,
  },

  // HIRE A DRIVER BOOKING
  {
    path: '/bookings/hire-a-driver',
    component: HireADriverBookingManagement,
    name: `HIRE_A_DRIVER_BOOKING_READ`,
  },

  // THRIWE PRIME BOOKING
  {
    path: '/bookings/thriwe-prime',
    component: ThriwePrimeBooking,
    name: `THRIWE_PRIME_BOOKING_READ`,
  },

  // DYNAMIC QR BOOKING
  {
    path: '/bookings/dynamic-qr-booking',
    component: DynamicQrBooking,
    name: `DYNAMIC_QR_BOOKING_READ`,
  },

  // CLUB BOOKING
  {
    path: '/bookings/club',
    component: ClubBookingManagement,
    name: `CLUB_BOOKING_READ`,
  },

  // DINING BOOKING
  {
    path: '/bookings/dining',
    component: DiningBooking,
    name: `OUTLET_BOOKING_READ`,
  },

  // DINING BOOKING
  {
    path: '/vendor/booking/dining',
    component: VendorDiningBooking,
    name: `DINING_BOOKING_MANAGEMENT`,
  },

  /* ---------------------------- INVENTORY SETTINGS ---------------------------- */
  {
    path: '/settings/airport-transfer',
    component: AirportTransfer,
    name: `AIRPORT_TRANSFER`,
  },

  /* ----------------------------------- DOWNLOAD ----------------------------------- */
  { path: '/downloads', component: Downloads, name: `DOWNLOAD_ACCESS` },
  { path: '/customer-downloads', component: CustomerDownloads, name: Constant.PROJECT_CODE === `HSBC_BEACH_CLUB` ? `DOWNLOAD_ACCESS` : `` },

  /* ----------------------------------- RULES MANAGEMENT ----------------------------------- */
  // EXPENDITURES
  {
    path: '/rules/expenditure',
    component: ExpenditureManagement,
    name: `EXPENDITURE_READ`,
  },

  // EVENTS
  { path: '/rules/event', component: EventManagement, name: `EVENT_READ` },

  // SERVICES
  {
    path: '/rules/service',
    component: ServiceWorthManagement,
    name: `ASSIGN_COINS`,
  },

  /* ----------------------------------- GOLF ADMIN MANAGEMENT ----------------------------------- */
  // COURSE WISE FORMATS
  {
    path: '/golf/course-wise-formats',
    component: CourseWiseFormats,
    name: `GOLF_ADMIN_MANAGEMENT`,
  },

  // EDIT COURSE FORMATS
  {
    path: '/golf/edit-course-formats',
    component: EditCourseFormats,
    name: `GOLF_ADMIN_MANAGEMENT`,
  },

  // REAL TIME SLOTS REPORT
  {
    path: '/golf/real-time-slots-report',
    component: RealTimeSlotsReport,
    name: `GOLF_ADMIN_MANAGEMENT`,
  },

  // SLOTS UNAVAILABILITY
  {
    path: '/golf/slots-unavailability',
    component: SlotUnavailability,
    name: `GOLF_ADMIN_MANAGEMENT`,
  },

  /* ----------------------------------- USER MANAGEMENT ----------------------------------- */
  {
    path: '/user-management',
    component: UserManagement,
    name: `ADD_USER_PERMISSION`,
  },

  /* ----------------------------------- PROJECT MANAGEMENT ----------------------------------- */
  // CLIENTS
  {
    path: '/clients',
    component: Clients,
    name: `CLIENTS`,
  },

  // PLATFORM
  {
    path: '/platform/config',
    component: Platform,
    name: `ALL_PLATFORMS`,
  },

  // PROGRAMS
  {
    path: '/program/config',
    component: Program,
    name: `ALL_PROGRAMS`,
  },

  // DATA INTEGRATION
  {
    path: '/project/data-integration',
    component: DataIntegration,
    name: `DATA_INTEGRATION`,
  },

  // COMMUNICATION SETTINGS
  {
    path: '/project/comm-settings',
    component: ProjectComs,
    name: `COMMS_SETTINGS`,
  },

  // PROJECT ONBOARDING
  {
    path: '/project/project-onboarding',
    component: ProgramOnboarding,
    name: `PROGRAM_ONBOARDING`,
  },

  // PAYMENT SETTINGS
  {
    path: '/payment/settings',
    component: ProjectPaymentsSettings,
    name: `PROJECT_PAYMENTS`,
  },

  // PAYMENT LOGS
  {
    path: '/payments/logs',
    component: PaymentLogs,
    name: `PAYMENT_LOGS`,
  },

  /* ----------------------------------- REPORTS MANAGEMENT ----------------------------------- */
  // COMMS REPORT
  {
    path: '/comms-report',
    component: CommsReport,
    name: `COMMS_REPORTS`,
  },

  /* ----------------------------------- CLIENT PANEL ----------------------------------- */
  // CLIENT AIRPORT TRANSFER
  {
    path: '/client/airport-transfer',
    component: AirportTransferManualBooking,
    name: `CLIENT_AIRPORT_TRANSFER`,
  },

  // CLIENT AIRPORT MEET & GREET
  {
    path: '/client/airport-meet-greet',
    component: MeetAndGreetManualBooking,
    name: `CLIENT_AIRPORT_MEET_AND_GREET`,
  },

  // CLIENT HISTORY
  {
    path: '/client/history',
    component: History,
    name: `CLIENT_HISTORY`,
  },

  // CLIENT CONTACT
  {
    path: '/client/contact',
    component: ContactUs,
    name: `CLIENT_CONTACT`,
  },

  // CLIENT DOWNLOAD
  {
    path: '/client/downloads',
    component: ClientDownloads,
    name: `CLIENT_DOWNLOAD_DUMP`,
  },

  /* ----------------------------------- VENDOR PANEL ----------------------------------- */
  // VENDOR DASHBOARD
  {
    path: '/vendor/dashboard',
    component: VendorPanelDashboard,
    name: `VENDOR_DASHBOARD`,
  },

  // VENDOR DUMP DOWNLOAD
  {
    path: '/vendor/download',
    component: VendorDownloads,
    name: `VENDOR_DOWNLOAD_DUMP`,
  },

  // VENDOR AIRPORT TRANSFER
  {
    path: '/vendor/booking/airport-transfer',
    component: VendorAirportTransfer,
    name: `VENDOR_AIRPORT_TRANSFER`,
  },

  // VENDOR VALET PARKING BOOKING
  {
    path: '/vendor/booking/valet-parking',
    component: VendorBookingValetParking,
    name: `VENDOR_VALET_PARKING`,
  },

  // VENDOR VALET PARKING QR
  {
    path: '/vendor/qr',
    component: QRPage,
    name: `VENDOR_QR`,
  },

  // VENDOR REPORT
  {
    path: '/vendor/report',
    component: VendorReport,
    name: `VENDOR_REPORT`,
  },
  /* ----------------------------------- GM REPORT ----------------------------------- */
  // CLIENT PRICING
  {
    path: '/client-pricing',
    component: ClientPricing,
    name: `CLIENT_DATA_GM_REPORT`,
  },

  // PRICE VIEWER
  {
    path: '/price-viewer',
    component: PriceViewer,
    name: `PRICE_VIEWER_GM_REPORT`,
  },

  // BOOKING RECON
  {
    path: '/booking-recon',
    component: BookingRecon,
    name: `BOOKING_RECON_GM_REPORT`,
  },

  // GM REPORT
  {
    path: '/gm-report',
    component: GmReport,
    name: `MASTER_GM_REPORT`,
    // VENDOR VALET PARKING BOOKING
  },
  {
    path: '/vendor/booking/valet-parking',
    component: VendorBookingValetParking,
    name: `VENDOR_VALET_PARKING`,
  },

  // VENDOR VALET PARKING QR
  {
    path: '/vendor/qr',
    component: QRPage,
    name: `VENDOR_QR`,
  },

  // VENDOR REPORT
  {
    path: '/vendor/report',
    component: VendorReport,
    name: `VENDOR_REPORT`,
  },

  // VALET PARKING
  {
    path: '/bookings/valet-parking',
    component: ValetParkingManagement,
    name: `VALET_PARKING_BOOKING_READ`,
  },

  // CLIENT BUSINESS REGISTRATION
  {
    path: '/client/business-registration',
    component: BusinessRegistration,
    name: `ADIB_READ`,
  },

  // CLIENT POST
  {
    path: '/client/post',
    component: Posts,
    name: `ADIB_READ`,
  },

  // CLIENT ADIB_WRITE
  {
    path: '/client/offer',
    component: Offers,
    name: `ADIB_READ`,
  },

  // CLIENT ANNOUNCEMENT
  {
    path: '/client/announcements',
    component: Annoucements,
    name: `ADIB_READ`,
  },

  // CLIENT ADIB USERMANAGEMENT
  {
    path: '/user-management-adib',
    component: UserManagementAdib,
    name: `ADIB_SUPERADMIN`,
  },

  // CLIENT CUSTOMERS
  {
    path: '/client/customers',
    component: ClientCustomers,
    name: `ADIB_READ`,
  },

  // CLIENT GOLF BOOKINGS
  {
    path: '/client/delayed-bookings',
    component: ClientGolfBooking,
    name: `ADIB_READ`,
  },

  // CLIENT UNIQUE COUPON BOOKINGS
  {
    path: '/client/unique-coupon-bookings',
    component: ClientUniqueCouponBooking,
    name: `CLIENT_UNIQUE_COUPON_BOOKINGS`,
  },

  // CLIENT BOOKINGS DOWNLOAD
  {
    path: '/client/bookings/downloads',
    component: ClientBookingsDownloads,
    name: Constant.PROJECT_CODE === 'ADIB_V2' ? `ADIB_DOWNLOAD` : `CLIENT_BOOKINGS_DOWNLOAD_DUMP`,
  },

  /* ----------------------------------- BOOKING MANAGEMENT V2----------------------------------- */
  // DELAYED BOOKING V2
  {
    path: '/bookings/delayed-booking-v2',
    component: DelayedBookingV2,
    name: `DELAYED_BOOKING_V2_READ`,
  },

  // UNIQUE COUPON V2
  {
    path: '/bookings/unique-booking-v2',
    component: UniqueCouponV2,
    name: `UNIQUE_BOOKING_V2_READ`,
  },

  // STATIC COUPON V2
  {
    path: '/bookings/static-booking-v2',
    component: StaticCouponV2,
    name: `STATIC_BOOKING_V2_READ`,
  },

  // GOLF BOOKING V2
  {
    path: '/bookings/golf-v2',
    component: GolfBookingManagementV2,
    name: `GOLF_BOOKING_V2_READ`,
  },

  // AIRPORT TRANSFER BOOKING
  {
    path: '/bookings/pick-drop-v2',
    component: PickAndDropBookingManagementV2,
    name: `PICKUP_AND_DROP_BOOKING_V2_READ`,
  },

  // QR BOOKING
  {
    path: '/bookings/qr-v2',
    component: QRBookingManagementV2,
    name: `QR_BOOKING_V2_READ`,
  },

  // CUSTOM DELAYED BOOKING
  {
    path: '/bookings/custom-delayed-booking-v2',
    component: CustomDelayedBookingV2,
    name: `CUSTOM_DELAYED_V2_READ`,
  },

  /* ----------------------------------- REVIEW----------------------------------- */
  // FEEDBACK
  {
    path: '/review/feedback',
    component: Feedback,
    name: `FEEDBACK`,
  },

  {
    path: '/review/query',
    component: Query,
    name: `QUERY`,
  },

  /* ----------------------------------- BOOKING SEARCH ----------------------------------- */

  {
    path: '/booking-search',
    component: BookingSearch,
    name: `BOOKING_COUPON_ENQUIRY_READ`,
  },
  
  // GM REPORT
  {
    path: '/bookings/valet-parking',
    component: ValetParkingManagement,
    name: `VALET_PARKING_BOOKING_READ`,
  },
];

export { authProtectedRoutes, publicRoutes };
