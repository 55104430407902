import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { Constant } from '../../../../../../../Helpers/constant';
import { formattedWithComma, hasPermission } from '../../../../../../../Helpers/Helpers';
import { PreviewCardHeader, TablePagination } from '../../../../../../../Common';
import DataTable from 'react-data-table-component';
import { PermissionConstants } from '../../../../../../../Helpers/Constants/permissions';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'Booking Id',
          width: '170px',
          selector: row => row?.bookingId,
          wrap: true,
        },
        {
          name: 'Name',
          selector: row => row?.name,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Email',
          selector: row => row?.email,
          wrap: true,
          minWidth: '300px',
        },
        {
          name: 'Mobile',
          selector: row => row?.mobile,
          width: '150px',
          wrap: true,
        },
        {
          name: 'Category',
          selector: row => row?.category,
          width: '180px',
          wrap: true,
        },
        {
          name: 'Partner',
          selector: row => row?.partner,
          width: '200px',
          wrap: true,
        },
        // {
        //   name: 'Facility',
        //   selector: row => row?.facility,
        //   width: '200px',
        //   wrap: true,
        // },
        {
          name: 'Service',
          selector: row => row?.service,
          width: '300px',
          wrap: true,
        },
        {
          name: 'Created At',
          selector: row => row?.createdAt,
          width: '200px',
          wrap: true,
        },

        {
          name: 'Booking Status',
          width: '150px',
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES.filter(status => status?.name === row?.bookingStatus)[0]?.bgColor
              }`}
            >
              {row?.bookingStatus}
            </span>
          ),
        },

        // {
        //   name: 'Actions',
        //   width: '200px',
        //   style: { justifyContent: 'center' },
        //   cell: row => (
        //     <div className="d-flex justify-content-center flex-column m-4">
        //       {
        //         <>
        //           {row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS?.PENDING && (
        //             <>
        //               <button
        //                 className="btn btn-sm btn-primary mb-1"
        //                 onClick={() => actionBtnHandler('PROCESS', row?.originalData)}
        //                 style={{ width: '150px' }}
        //               >
        //                 Process
        //               </button>

        //               <button
        //                 className="btn btn-sm btn-danger mb-1"
        //                 onClick={() => actionBtnHandler('CANCEL', row?.originalData)}
        //                 style={{ width: '150px' }}
        //               >
        //                 Cancel
        //               </button>
        //             </>
        //           )}

        //           {row?.originalData?.bookingStatusCode === Constant?.BOOKING_STATUS?.PROCESSED && (
        //             <>
        //               <button
        //                 className="btn btn-sm btn-primary mb-1"
        //                 onClick={() => actionBtnHandler('PROCESS', row?.originalData)}
        //                 style={{ width: '150px' }}
        //               >
        //                 Confirm
        //               </button>

        //               <button
        //                 className="btn btn-sm btn-danger mb-1"
        //                 onClick={() => actionBtnHandler('CANCEL', row?.originalData)}
        //                 style={{ width: '150px' }}
        //               >
        //                 Cancel
        //               </button>
        //             </>
        //           )}
        //         </>
        //       }
        //     </div>
        //   ),
        // },
      ];

      setColumns(fetchColumns);
      setData(
        props?.data?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;

          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            bookingId: data?.bookingId || `NA`,
            name: data?.user?.name || `NA`,
            email: data?.user?.email || `NA`,
            mobile: data?.user?.mobile || `NA`,
            category: data?.category?.name || `NA`,
            partner: data?.partner?.name || `NA`,
            facility: data?.facility?.name || `NA`,
            service: data?.service?.name || `NA`,
            createdAt: data?.createdAt ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            bookingStatus: status,
            originalData: data || `NA`,
          };
        }),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `PROCESS`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CANCEL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{props?.title}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.ADIB_DOWNLOAD) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns}
                data={data}
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                      padding: '10px',
                    },
                  },
                }}
              />
            </CardBody>

            {data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(String(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1))}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
