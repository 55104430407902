import { PublicClientApplication } from '@azure/msal-browser';

// authConfig.js
export const msalConfig = {
  auth: {
    clientId: 'c01a369d-e25d-4748-b700-9437e6c1cb1f', // Application (client) ID from the Azure portal
    authority: 'https://login.microsoftonline.com/72c1f8e0-64ed-4939-a478-37db6d05de85',
    // clientId: 'b6b46294-6f28-49d0-b858-383e246970c5', // Application (client) ID from the Azure portal
    // authority: 'https://login.microsoftonline.com/94ef96d0-bf8d-41fd-9c27-48a0e57a9063',
    redirectUri: `${window.location.origin}/`, // Dynamic redirect URI based on the current environment
  },
  cache: {
    cacheLocation: 'localStorage', // Enables the token to be stored in local storage
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
};

export const msalInstance = new PublicClientApplication(msalConfig);
