import React, { useEffect, useState } from 'react';

import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from './../../../pages/AuthenticationInner/ParticlesAuth';
import jwt_decode from 'jwt-decode';

//redux
import { useSelector, useDispatch } from 'react-redux';
import cogoToast from 'cogo-toast';

import { withRouter, Link, useHistory, useParams } from 'react-router-dom';

import ThriweWhite from './../../../assets/custom/thriwe-white-logo.png';

import { LOGIN } from '../../../store/application/actionType';
import { GoogleLogin } from '@react-oauth/google';
import FullLoader from '../../Common/Loader/CompleteLoader';
import { Constant } from '../../Helpers/constant';
import MicrosoftLogin from 'react-microsoft-login';
import { GET_PROJECT_UTILITY } from '../../../store/application/actionType';

const Login = props => {
  const dispatch = useDispatch();
  const [client, setClient] = useState('MASTERCARD_KSA');
  const { clientId } = useParams();
  const history = useHistory();

  const { getProjectUtility } = useSelector(state => ({
    getProjectUtility: state.GetProjectUtility.data?.results,
  }));
  const [projectUtility, setProjectUtility] = useState();
  useEffect(() => {
    console.log('clientID', clientId);

    if (clientId && clientId == 'landmark') {
      console.log('get projectUtility1');
      setClient(clientId);
      dispatch({ type: GET_PROJECT_UTILITY });
    } else {
      console.log('remove projectUtility1');
      localStorage.removeItem('projectUtility');
    }
  }, [clientId]);
  const { user } = useSelector(state => ({
    user: state.Account.user,
  }));

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  const [isLoading, setIsLoading] = useState(false);

  /* -------------------- Custom Code -------------------- */
  const { userLogin, userLoginLoading, userLoginError, userLoginErrorMsg } = useSelector(state => ({
    userLogin: state.UserLogin.data,
    userLoginLoading: state.UserLogin.loading,
    userLoginError: state.UserLogin.error,
    userLoginErrorMsg: state.UserLogin.message,
  }));

  useEffect(() => {
    if (userLoginLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userLoginLoading]);

  useEffect(() => {
    if (![null, undefined, -1].includes(window.localStorage.getItem('authUser'))) {
      let redirectionRoute = `/`;
      const userType = parseInt(JSON.parse(window.localStorage.getItem('userType') ?? -1));
      if (userType === Constant?.userTypes?.ADMIN_USER) {
        redirectionRoute = `/dashboard`;
      } else if (userType === Constant?.userTypes?.CLIENT) {
        redirectionRoute = `/client/dashboard`;
      } else if (userType === Constant?.userTypes?.VENDOR) {
        redirectionRoute = `/vendor/dashboard`;
      } else {
        redirectionRoute = `/dashboard`;
      }
      history.push(`${redirectionRoute}`);
    }
  }, []);

  useEffect(() => {
    if (userLogin !== null) {
      localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.ADMIN_USER ?? -1));

      localStorage.setItem(
        'authUser',
        JSON.stringify({
          status: 'success',
          token: userLogin?.headers?.authorization,
          data: {
            userId: userLogin?.data?.objectId,
            name: userLogin?.data?.name,
          },
          permissions: jwt_decode(userLogin?.headers?.authorization)?.permissions ?? [],
        }),
      );

      history.push(`/dashboard`);
    }
  }, [userLogin]);

  const initialState = {
    username: '',
    password: '',
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  // ---------------------------------------------------------
  const [googleResponse, setGoogleResponse] = useState(null);

  const googleLoginResSuccessHandler = credentialsResponse => {
    setGoogleResponse(jwt_decode(credentialsResponse?.credential));
    const tempGoogleResponse = jwt_decode(credentialsResponse?.credential);

    if (!`${tempGoogleResponse?.email}`?.split('@')[1]?.includes('thriwe.com')) {
      cogoToast.error(`Login allowed for Thriwers only.`);
      return false;
    } else {
      const payload = {
        email: `${tempGoogleResponse?.email}`,
        name: `${tempGoogleResponse?.name}`,
        clientId: 'qwerty',
        loginToken: credentialsResponse,
      };

      dispatch({
        type: LOGIN,
        payload: {
          data: payload,
        },
      });

      window.localStorage.setItem('profilePic', tempGoogleResponse?.picture);
    }
  };

  const microsoftLogin = data => {
    if (!data?.account?.username?.split('@')?.includes('thriwe.com')) {
      cogoToast.error(`Login allowed for Thriwers only.`);
      return false;
    } else {
      const payload = {
        email: `${data?.account?.username}`?.toLocaleLowerCase(),
        name: `${data?.account?.name}`,
        clientId: 'qwerty',
        // loginToken: data,
        loginToken: {
          clientId: `${data?.account?.tenantId}`,
          client_id: `${data?.account?.tenantId}`,
          credential: `${data?.accessToken}`,
          select_by: 'btn',
        },
      };

      dispatch({
        type: LOGIN,
        payload: {
          data: payload,
        },
      });
    }

    // window.localStorage.setItem('profilePic', tempGoogleResponse?.picture);
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        {userLoginLoading && <FullLoader />}
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">Sign in to continue to Thriwe.</p>
                    </div>

                    <Row className="">
                      <div className="d-flex justify-content-center">
                        <div>
                          <GoogleLogin
                            onSuccess={credentialsResponse => {
                              googleLoginResSuccessHandler(credentialsResponse);
                            }}
                            onError={() => {
                              cogoToast.error('Login failed!');
                            }}
                          />
                        </div>
                      </div>
                    </Row>

                    <br />

                    <Row className="">
                      <div className="d-flex justify-content-center">
                        <MicrosoftLogin
                          clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
                          authCallback={(err, data) => {
                            if (err) {
                              cogoToast.error('Login failed!');
                            } else {
                              microsoftLogin(data);
                            }
                          }}
                        />
                        <div></div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
