import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { UPDATE_USER_STATUS, UPDATE_USER_STATUS_SUCCESS, UPDATE_USER_STATUS_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* updateUserStatus({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/v1/update`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Project-Code': 'ADMIN_ACCESS',
      },
      data: payload?.data,
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: UPDATE_USER_STATUS_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: UPDATE_USER_STATUS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_USER_STATUS_ERROR, payload: error });
  }
}

export function* watchupdateUserStatus() {
  yield takeEvery(UPDATE_USER_STATUS, updateUserStatus);
}

function* updateUserStatusSaga() {
  yield all([fork(watchupdateUserStatus)]);
}

export default updateUserStatusSaga;
