import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_BOOKING_SEARCH_LIST, GET_BOOKING_SEARCH_LIST_SUCCESS, GET_BOOKING_SEARCH_LIST_ERROR } from '../../actionType.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import apiCall from '../../apiCall.js';

function* getBookingSearch({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/coupon-booking-enquiry`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        // bookingId: payload?.urlParam?.bookingId,
        // membershipId: payload?.urlParam?.membershipId,
        // projectCode: payload?.urlParam?.projectCode,
        // supportPanel: payload?.urlParam?.supportPanel,
        // pageIndex: payload?.urlParam?.pageIndex,
        // pageSize: Constant?.TABLE_PAGE_SIZE,
        // sortOn: payload?.urlParam?.sortOn,

      },
      data: payload?.data,
    });

    if (response.status === 200) {
      // yield put({
      //   type: MAIN_AREA_LOADER_HIDE,
      // })

      yield put({
        type: GET_BOOKING_SEARCH_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_BOOKING_SEARCH_LIST_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_BOOKING_SEARCH_LIST_ERROR, payload: error });
  }
}

export function* watchBookingSearch() {
  yield takeEvery(GET_BOOKING_SEARCH_LIST, getBookingSearch);
}

function* BookingSearchSaga() {
  yield all([fork(watchBookingSearch)]);
}

export default BookingSearchSaga;
