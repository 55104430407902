import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import DataTable from 'react-data-table-component';
import { capitalizeFirstLetter, formattedWithComma } from '../../../../../Helpers/Helpers';

const status = [`In-process`, `Partially Uploaded`, `Uploaded`];

const Table = props => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '70px',
        },
        {
          name: 'File Name',
          width: '150px',
          wrap: true,
          cell: row => (
            <span
              className="fw-semibold"
              style={{
                cursor: 'pointer',
                color: '#3577f1',
              }}
            >
              {row?.fileName}
            </span>
          ),
        },
        {
          name: 'User',
          width: '150px',
          wrap: true,
          selector: row => row?.user,
        },
        {
          name: 'Errors',
          wrap: true,
          selector: row => row?.error,
        },
        {
          name: 'Type',
          width: '180px',
          wrap: true,
          selector: row => row?.type,
        },
        {
          name: 'Status',
          width: '200px',
          wrap: true,
          selector: row => row?.status,
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            fileName: capitalizeFirstLetter(data?.filename) || ``,
            user: data?.user?.name || `NA`,
            error: data?.error?.join(`, `) || `NA`,
            type: data?.type || `NA`,
            status: status[data?.status - 1],
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <PreviewCardHeader title="Upload Status" refreshBtn={props?.data?.length > 0 ? true : false} refreshHandler={props?.refreshHandler} />

          <Card>
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
