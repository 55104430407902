import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, ModalBody, ModalHeader, Input, Label, Col, Row } from 'reactstrap';
import { Constant } from '../../../../../../../Helpers/constant';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import {
  GET_CLIENT_ROLES,
  GET_CLIENT_ROLES_FOR_USER,
  UPDATE_CLIENT_ROLE,
  UPDATE_CLIENT_ROLE_EMPTY,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_EMPTY,
} from '../../../../../../../../store/actions';
import { Loader } from '../../../../../../../Common';
import Select from 'react-select';

const UpdateUserPermission = ({ selectedUser, onCloseHandler }) => {
  const dispatch = useDispatch();

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isPermissionState, setIsPermissionState] = useState({}); // Default to an empty object
  const [loading, setLoading] = useState(false);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [permissionValue, setPermissionValue] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Permission
  const { role, roleLoading } = useSelector((state: any) => ({
    role: state?.GetClientRole?.data,
    roleLoading: state?.GetClientRole?.loading,
  }));

  // Get User Permission
  const { getUserRole, getUserRoleLoading } = useSelector((state: any) => ({
    getUserRole: state?.GetClientRoleForUser?.data,
    getUserRoleLoading: state?.GetClientRoleForUser?.loading,
  }));

  // Get User Permission
  const { updateClientRole, updateClientRoleLoading } = useSelector((state: any) => ({
    updateClientRole: state?.UpdateClientRole?.data,
    updateClientRoleLoading: state?.UpdateClientRole?.loading,
  }));

  // Update User Status
  const { updateUserStatus, updateUserStatusLoading } = useSelector((state: any) => ({
    updateUserStatus: state?.UpdateUserStatus?.data,
    updateUserStatusLoading: state?.UpdateUserStatus?.loading,
  }));

  useEffect(() => {
    if (role && getUserRole) {
      setPermissionOptions(
        role.map(permission => ({
          value: permission?.code,
          label: permission?.code,
        })),
      );
      setPermissionValue({ value: getUserRole?.[0]?.code, label: getUserRole?.[0]?.code });
    }
  }, [role, getUserRole]);

  useEffect(() => {
    dispatch({
      type: GET_CLIENT_ROLES,
    });
    dispatch({
      type: GET_CLIENT_ROLES_FOR_USER,
      payload: {
        urlParams: {
          userId: selectedUser?.userId,
        },
      },
    });
  }, [selectedUser]);

  useEffect(() => {
    if (updateClientRole || updateUserStatus) {
      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
      dispatch({
        type: UPDATE_CLIENT_ROLE_EMPTY,
      });
      dispatch({
        type: UPDATE_USER_STATUS_EMPTY,
      });
    }
  }, [updateClientRole, updateUserStatus]);

  useEffect(() => {
    if (roleLoading || getUserRoleLoading || updateClientRoleLoading || updateUserStatusLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [roleLoading, getUserRoleLoading, updateClientRoleLoading, updateUserStatusLoading]);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    dispatch({
      type: UPDATE_CLIENT_ROLE,
      payload: {
        data: {
          roleCodes: [permissionValue.value],
          userIds: [selectedUser?.userId],
          action: 'attach',
        },
      },
    });
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const inputSelectHandler = (label, data) => {
    if (label === `permission-name`) {
      setPermissionValue(data);
    }
  };

  const userStatusHandler = () => {
    dispatch({
      type: UPDATE_USER_STATUS,
      payload: {
        urlParams: {
          user_id: selectedUser?.userId,
          status: !selectedUser?.active,
        },
      },
    });
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Edit User
        </h5>
        <div className="d-flex align-items-center">
          <span className="text-muted">De Active &nbsp;</span>
          <div className="form-switch d-inline-block ">
            <Input
              className="form-check-input code-switcher form-switch-md cursor-pointer"
              type="checkbox"
              id="complementaryBenefit"
              name="complementaryBenefit"
              checked={selectedUser?.active}
              onChange={userStatusHandler}
            />
          </div>
          <span> &nbsp;Active</span>
        </div>
      </div>
      {loading && <Loader zIndex={true} />}

      <ModalBody>
        <form>
          <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="name" className="form-label">
                  Name <span className="lbl_mandatory">*</span>
                </Label>
                <Input id="name" name="name" className="form-control" value={selectedUser?.firstName} disabled />
              </div>
            </Col>
          </div>

          <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="email" className="form-label">
                  Email <span className="lbl_mandatory">*</span>
                </Label>
                <Input id="email" name="email" type="text" className="form-control" value={selectedUser?.email} disabled />
              </div>
            </Col>
          </div>

          <div className="mb-3">
            <Label htmlFor="name" className="form-label">
              User Rights
            </Label>
            <Row>
              <Select
                id="permission-name"
                name="permission-name"
                options={permissionOptions ?? []}
                value={permissionValue}
                onChange={data => inputSelectHandler(`permission-name`, data)}
              />
            </Row>
          </div>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateUserPermission;
