// Actions
export const API_RESPONSE_SUCCESS = 'API_RESPONSE_SUCCESS';
export const API_RESPONSE_ERROR = 'API_RESPONSE_ERROR';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_DEALS = 'GET_DEALS';
export const GET_LEADS = 'GET_LEADS';

// Add ADIB_READ
export const ADD_NEW_COMPANIES = 'ADD_NEW_COMPANIES';
export const ADD_COMPANIES_SUCCESS = 'ADD_COMPANIES_SUCCESS';
export const ADD_COMPANIES_FAIL = 'ADD_COMPANIES_FAIL';

// Edit ADIB_READ
export const UPDATE_COMPANIES = 'UPDATE_COMPANIES';
export const UPDATE_COMPANIES_SUCCESS = 'UPDATE_COMPANIES_SUCCESS';
export const UPDATE_COMPANIES_FAIL = 'UPDATE_COMPANIES_FAIL';

// Delete ADIB_READ
export const DELETE_COMPANIES = 'DELETE_COMPANIES';
export const DELETE_COMPANIES_SUCCESS = 'DELETE_COMPANIES_SUCCESS';
export const DELETE_COMPANIES_FAIL = 'DELETE_COMPANIES_FAIL';

// Add CONTACT
export const ADD_NEW_CONTACT = 'ADD_NEW_CONTACT';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_FAIL = 'ADD_CONTACT_FAIL';

// Edit CONTACT
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAIL = 'UPDATE_CONTACT_FAIL';

// Delete CONTACT
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';

// Add LEAD
export const ADD_NEW_LEAD = 'ADD_NEW_LEAD';
export const ADD_LEAD_SUCCESS = 'ADD_LEAD_SUCCESS';
export const ADD_LEAD_FAIL = 'ADD_LEAD_FAIL';

// Edit LEAD
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const UPDATE_LEAD_SUCCESS = 'UPDATE_LEAD_SUCCESS';
export const UPDATE_LEAD_FAIL = 'UPDATE_LEAD_FAIL';

// Delete LEAD
export const DELETE_LEAD = 'DELETE_LEAD';
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_FAIL = 'DELETE_LEAD_FAIL';

export const RESET_CRM_FLAG = 'RESET_CRM_FLAG';
