import roboto from '../../../../../../assets/fonts/Roboto/Roboto-Bold.ttf';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import moment from 'moment';
import { useEffect } from 'react';
const borderColor = '#e1e1e1';
const PDFReportDocument = ({ filteredInvoiceData, CURRENCY_NAME, searchFormData, costPriceUploadedDate, sellPriceUploadedDate }) => {
  useEffect(() => {
    console.log('====================================');
    console.log('costPriceUploadedDate', costPriceUploadedDate);
    console.log('====================================');
  }, [costPriceUploadedDate]);

  useEffect(() => {
    console.log('====================================');
    console.log('sellPriceUploadedDate', sellPriceUploadedDate);
    console.log('====================================');
  }, [sellPriceUploadedDate]);

  let dateTo = undefined;
  let dateFrom = undefined;
  if (searchFormData?.searchType == 2) {
    dateTo = searchFormData?.dateTo?.[0] ?? '';
    dateFrom = searchFormData?.dateFrom?.[0] ?? '';
  } else {
    dateTo = searchFormData?.date?.[0] ?? '';
    dateFrom = searchFormData?.date?.[0] ?? '';
  }

  let to = dateTo ? moment(dateTo).format('DD MMM YYYY') : '';
  let from = dateFrom ? moment(dateFrom).format('DD MMM YYYY') : '';

  Font.register({ family: 'Roboto', src: roboto });

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#E4E4E4',
      padding: '20px 20px',
    },
    display: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: '20px',
    },
    textRight: {
      flexDirection: 'column',
      alignSelf: 'flex-end',
    },
    heading1: {
      fontSize: '8px',
    },
    heading2: {
      fontSize: '11px',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    heading222: {
      fontSize: '9px',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    card111: {
      backgroundColor: 'white',
      padding: '20px 20px',
      borderRadius: '5px',
    },
    marginTop: {
      marginTop: '2px',
    },

    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 15,
      borderWidth: 1,
      borderColor: borderColor,
    },
    container: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      backgroundColor: borderColor,
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      textAlign: 'center',
      flexGrow: 1,
      width: '100%',
      padding: '0px 10px',
    },
    container: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      backgroundColor: borderColor,
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      // textAlign: 'center',
      flexGrow: 1,
    },
    row: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      // textAlign: 'center',
    },
    row2: {
      flexDirection: 'row',
      alignItems: 'center',
      // height: 24,
    },
    tdHead: {
      fontSize: '7px',
      height: '100%',
      padding: '2px',
      paddingTop: '8px',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    tdBody: {
      fontSize: '6px',
      height: '100%',
      padding: '2px',
      paddingTop: '8px',
    },
    baseCostPrice: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    baseSellPrice: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    processingFees: {
      width: '12%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    telComCharges: {
      width: '12%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    otherCharge: {
      width: '12%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    tax: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    gdp: {
      width: '12%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    finalCostPrice: {
      width: '12%',
    },
    mrp: {
      width: '32%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    noShowCharges: {
      width: '32%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    shortCancelCharges: {
      width: '36%',
    },
    noShowCharges1: {
      width: '50%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
    },
    shortCancelCharges1: {
      width: '50%',
    },
    inline: {
      display: 'flex',
      flexDirection: 'row',
      gap: '7px',
    },
    headingFilter: {
      fontSize: '8px',
      padding: '2px',
      paddingTop: '8px',
    },
    dataFilter: {
      fontSize: '6px',
      padding: '2px',
      // paddingTop: "8px",
      fontFamily: 'Roboto',
    },
    golfCourse: {
      width: '16.7%',
    },
    service: {
      width: '16.7%',
    },
    contractType: {
      width: '16.7%',
    },
    customerType: {
      width: '16.7%',
    },
    elements: {
      width: '16.7%',
    },
    dateRange: {
      width: '16.7%',
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.card111}>
          <View
            style={{
              ...styles.layout4,
              ...styles.inline,
              borderBottomColor: borderColor,
              borderBottomWidth: 1,
              paddingBottom: 5,
            }}
          >
            <Text style={{ ...styles.heading2, ...styles.inline }}>Pricing Viewer - Output</Text>
            <Text
              style={{
                ...styles.heading2,
                ...styles.inline,
                backgroundColor: borderColor,
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {filteredInvoiceData?.isPeakDay ? 'Peak Day' : 'Non Peak Day'}
            </Text>
          </View>

          <View>
            <View style={{ ...styles.row2, marginTop: 15 }}>
              <Text style={{ ...styles.golfCourse, ...styles.headingFilter }}>Golf Course</Text>
              <Text style={{ ...styles.service, ...styles.headingFilter }}>Service</Text>
              <Text style={{ ...styles.contractType, ...styles.headingFilter }}>Contract Type</Text>
              <Text style={{ ...styles.customerType, ...styles.headingFilter }}>Customer Type</Text>
              <Text style={{ ...styles.elements, ...styles.headingFilter }}>Elements</Text>
              <Text style={{ ...styles.dateRange, ...styles.headingFilter }}>Date Range</Text>
            </View>
            <View style={styles.row2}>
              <Text style={{ ...styles.golfCourse, ...styles.dataFilter }}>{searchFormData?.program?.label}</Text>
              <Text style={{ ...styles.service, ...styles.dataFilter }}>{searchFormData?.service?.label}</Text>
              <Text style={{ ...styles.contractType, ...styles.dataFilter }}>{searchFormData?.contractType?.label}</Text>
              <Text style={{ ...styles.customerType, ...styles.dataFilter }}>{searchFormData?.customerType?.label}</Text>
              <Text style={{ ...styles.elements, ...styles.dataFilter }}>{searchFormData?.amenitiesPresentLabel?.join(', ')}</Text>
              <Text style={{ ...styles.dateRange, ...styles.dataFilter }}>{from && to && `${from} to ${to}`}</Text>
            </View>
          </View>
        </View>
        <View style={{ ...styles.card111, marginTop: '10px' }}>
          <View>
            <Text style={styles.heading2}>Cost Price</Text>
          </View>
          <View style={styles.display}>
            <View>
              <Text style={styles.heading1}>Applicable Price Name</Text>
              <Text style={{ ...styles.heading222, ...styles.marginTop }}>{filteredInvoiceData?.golfPriceViewer?.vendor?.priceName ?? ''}</Text>
            </View>
            <View>
              <Text style={{ ...styles.textRight, ...styles.heading1 }}>Program Name</Text>
              <Text style={{ ...styles.textRight, ...styles.heading222, ...styles.marginTop }}>
                {filteredInvoiceData?.golfPriceViewer?.vendor?.programName ?? ''}
              </Text>
            </View>
          </View>

          <View style={styles.display}>
            <View>
              <Text style={{ ...styles.heading1 }}>Uploaded on</Text>
              <Text style={{ ...styles.heading222, ...styles.marginTop }}>
                {costPriceUploadedDate?.value ? moment(costPriceUploadedDate?.value).format('DD MMM YYYY') : ''}
              </Text>
            </View>
            <View>
              <Text style={{ ...styles.textRight, ...styles.heading1 }}>Applicable from</Text>
              <Text style={{ ...styles.textRight, ...styles.heading222, ...styles.marginTop }}>
                {filteredInvoiceData?.golfPriceViewer?.vendor?.validity?.from &&
                  filteredInvoiceData?.golfPriceViewer?.vendor?.validity?.to &&
                  `${moment(filteredInvoiceData?.golfPriceViewer?.vendor?.validity?.from).format('DD MMM YYYY')} to ${moment(
                    filteredInvoiceData?.golfPriceViewer?.vendor?.validity?.to,
                  ).format('DD MMM YYYY')}`}
              </Text>
            </View>
          </View>

          <View style={styles.tableContainer}>
            <View style={styles.container}>
              <Text style={{ ...styles.baseCostPrice, ...styles.tdHead }}>Base Cost Price</Text>
              <Text style={{ ...styles.processingFees, ...styles.tdHead }}>Processing Fees</Text>
              <Text style={{ ...styles.telComCharges, ...styles.tdHead }}>Telcom Charges</Text>
              <Text style={{ ...styles.otherCharge, ...styles.tdHead }}>Other Charge</Text>
              <Text style={{ ...styles.tax, ...styles.tdHead }}>Tax</Text>
              <Text style={{ ...styles.gdp, ...styles.tdHead }}>GDF</Text>
              <Text style={{ ...styles.finalCostPrice, ...styles.tdHead }}>Final Cost Price</Text>
            </View>

            <View style={styles.row}>
              <Text style={{ ...styles.baseCostPrice, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.vendor?.costPrice ?? ''}</Text>
              <Text style={{ ...styles.processingFees, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.vendor?.processingFee ?? ''}</Text>
              <Text style={{ ...styles.telComCharges, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.vendor?.telecomCharges ?? ''}</Text>
              <Text style={{ ...styles.otherCharge, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.vendor?.otherCharges ?? ''}</Text>
              <Text style={{ ...styles.tax, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.vendor?.tax ?? ''}</Text>
              <Text style={{ ...styles.gdp, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.vendor?.gdf ?? ''}</Text>
              <Text style={{ ...styles.finalCostPrice, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.vendor?.finalCost ?? ''}</Text>
            </View>
          </View>

          <View style={{ marginTop: 20 }}>
            <View>
              <Text style={styles.heading2}>More Charges</Text>
            </View>

            <View style={{ ...styles.tableContainer, marginTop: 10 }}>
              <View style={{ ...styles.container }}>
                <Text style={{ ...styles.mrp, ...styles.tdHead }}>MRP</Text>
                <Text style={{ ...styles.noShowCharges, ...styles.tdHead }}>No Show Charges</Text>
                <Text style={{ ...styles.shortCancelCharges, ...styles.tdHead }}>Short Cancel Charges</Text>
              </View>

              <View style={styles.row}>
                <Text style={{ ...styles.mrp, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.vendor?.mrp ?? ''}</Text>
                <Text style={{ ...styles.noShowCharges, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.vendor?.noShowCharges ?? ''}</Text>
                <Text style={{ ...styles.shortCancelCharges, ...styles.tdBody }}>
                  {filteredInvoiceData?.golfPriceViewer?.vendor?.shortNoticeCancel ?? ''}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ ...styles.card111, marginTop: '10px' }}>
          <View style={styles.display}>
            <View>
              <Text style={styles.heading2}>Sell Price</Text>
            </View>
            <View>
              <Text style={{ ...styles.textRight, ...styles.heading1 }}>Applicable Price Name</Text>
              <Text style={{ ...styles.textRight, ...styles.heading222, ...styles.marginTop }}>
                {filteredInvoiceData?.golfPriceViewer?.client?.priceName ?? ''}{' '}
              </Text>
            </View>
          </View>

          <View style={styles.display}>
            <View>
              <Text style={{ ...styles.heading1 }}>Uploaded on</Text>
              <Text style={{ ...styles.heading222, ...styles.marginTop }}>
                {sellPriceUploadedDate?.value ? moment(sellPriceUploadedDate?.value).format('DD MMM YYYY') : ''}
              </Text>
            </View>
            <View>
              <Text style={{ ...styles.textRight, ...styles.heading1 }}>Applicable from</Text>
              <Text style={{ ...styles.textRight, ...styles.heading222, ...styles.marginTop }}>
                {filteredInvoiceData?.golfPriceViewer?.client?.validity?.from &&
                  filteredInvoiceData?.golfPriceViewer?.client?.validity?.to &&
                  `${moment(filteredInvoiceData?.golfPriceViewer?.client?.validity?.from).format('DD MMM YYYY')} to ${moment(
                    filteredInvoiceData?.golfPriceViewer?.client?.validity?.to,
                  ).format('DD MMM YYYY')}`}
              </Text>
            </View>
          </View>

          <View style={styles.tableContainer}>
            <View style={styles.container}>
              <Text style={{ ...styles.baseSellPrice, ...styles.tdHead }}>Base Sell Price</Text>
              <Text style={{ ...styles.processingFees, ...styles.tdHead }}>Processing Fees</Text>
              <Text style={{ ...styles.telComCharges, ...styles.tdHead }}>Telcom Charges</Text>
              <Text style={{ ...styles.otherCharge, ...styles.tdHead }}>Other Charge</Text>
              <Text style={{ ...styles.tax, ...styles.tdHead }}>Tax</Text>
              <Text style={{ ...styles.gdp, ...styles.tdHead }}>GDF</Text>
              <Text style={{ ...styles.finalCostPrice, ...styles.tdHead }}>Final Cost Price</Text>
            </View>

            <View style={styles.row}>
              <Text style={{ ...styles.baseCostPrice, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.client?.sellPrice ?? ''}</Text>
              <Text style={{ ...styles.processingFees, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.client?.processingFee ?? ''} </Text>
              <Text style={{ ...styles.telComCharges, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.client?.telecomCharges ?? ''}</Text>
              <Text style={{ ...styles.otherCharge, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.client?.otherCharges ?? ''}</Text>
              <Text style={{ ...styles.tax, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.client?.tax ?? ''}</Text>
              <Text style={{ ...styles.gdp, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.client?.gdf ?? ''}%</Text>
              <Text style={{ ...styles.finalCostPrice, ...styles.tdBody }}>{filteredInvoiceData?.golfPriceViewer?.client?.finalCost ?? ''}</Text>
            </View>
          </View>

          <View style={{ marginTop: 20 }}>
            <View>
              <Text style={styles.heading2}>More Charges</Text>
            </View>

            <View style={{ ...styles.tableContainer, marginTop: 10 }}>
              <View style={{ ...styles.container }}>
                <Text style={{ ...styles.noShowCharges1, ...styles.tdHead }}>No Show Charges</Text>
                <Text style={{ ...styles.shortCancelCharges1, ...styles.tdHead }}>Short Cancel Charges</Text>
              </View>

              <View style={styles.row}>
                <Text style={{ ...styles.noShowCharges1, ...styles.tdBody }}>
                  {filteredInvoiceData?.golfPriceViewer?.client?.noShowCharges ?? ''}
                </Text>
                <Text style={{ ...styles.shortCancelCharges1, ...styles.tdBody }}>
                  {filteredInvoiceData?.golfPriceViewer?.client?.shortNoticeCancel ?? ''}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.display}>
            <View>
              <Text style={styles.heading1}>Applicable Price Name</Text>
            </View>
            <View>
              <Text style={{ ...styles.textRight, ...styles.heading1 }}>Program Name</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFReportDocument;
