import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';

const ConfirmModal = ({ data, onCloseHandler, updateUserHandler }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modal_varying1, setModalVarying1] = useState(true);

  // Card List
  const { updateUser } = useSelector(state => ({
    updateUser: state?.UpdateClientsCustomer?.data,
  }));

  useEffect(() => {
    if (updateUser !== null) {
      closeHandler();
    }
  }, [updateUser]);

  const tog_varying1 = () => {
    onCloseHandler(false);
    setModalVarying1(!modal_varying1);
  };

  const closeHandler = () => {
    onCloseHandler(false);
    setModalVarying1(!modal_varying1);
  };

  const updateHandler = () => {
    setLoading(true);
    updateUserHandler(data);
  };

  return (
    <Modal centered isOpen={true} scrollable={true} id="user-cards-list" size="md">
      <div className="modal-header p-3 bg-soft-warning">
        <h5 className="modal-title" id="createboardModalLabel">
          {data?.isActive ? 'Deactivate User' : 'Activate User'}
        </h5>
        <Button type="button" onClick={closeHandler} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody style={{ padding: '20px', textAlign: 'center' }}>
        {data?.isActive ? 'Are you sure you want to deactivate this user?' : 'Are you sure you want to activate this user?'}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center pt-3">
        <Button className="btn btn-primary" color="primary" onClick={updateHandler}>
          {loading ? (
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : data?.isActive ? (
            'Deactivate'
          ) : (
            'Activate'
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
