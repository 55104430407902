import { useEffect, useState } from 'react';

import moment from 'moment';
import { FaEllipsisV } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

import { TablePagination } from '../../../../../../../Common';
import { Constant } from '../../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../../Helpers/Constants/permissions';
import { formattedWithComma, hasPermission } from '../../../../../../../Helpers/Helpers';
import { adibProjectsUrl } from '../../../../../../../Config';

// Define the shape of your Redux state
interface RootState {
  UpdateBooking: {
    data: any;
    loading: boolean;
    error: any;
  };

  ResendMail: {
    data: any;
    loading: boolean;
    error: any;
  };
}

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const statusMapping = {
    1: { text: 'Pending', color: 'warning' },
    2: { text: 'Accepted', color: 'success' },
    3: { text: 'Rejected', color: 'danger' },
    4: { text: 'Blocked', color: 'danger' },
  };

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'Company',
          width: '200px',
          selector: row => row?.companyName,
          wrap: true,
        },

        {
          name: 'Category',
          width: '150px',
          selector: row => row?.category,
          wrap: true,
        },
        {
          name: 'Location',
          width: '170px',
          selector: row => row?.location,
          wrap: true,
        },
        {
          name: 'Date & Time',
          selector: row => row?.dateAndTime,
          wrap: true,
          width: '180px',
        },
        {
          name: 'Status',
          cell: row => {
            const status = statusMapping[row?.status] || { text: 'Unknown', color: 'muted' };
            return <span className={`badge bg-${status?.color}`}>{status?.text}</span>;
          },
          width: '150px',
          wrap: true,
        },
        {
          name: 'Actions',
          width: '200px',
          style: { justifyContent: 'center' },
          cell: row => (
            <div className="d-flex justify-content-center flex-column m-3">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.ADIB_WRITE) && (
                <>
                  {row?.originalData?.status === 1 && (
                    <>
                      <button
                        className="btn btn-sm btn-success mb-1"
                        onClick={() => actionBtnHandler('ACCEPT', row?.originalData)}
                        style={{ width: '150px' }}
                      >
                        Accept
                      </button>
                      <button
                        className="btn btn-sm btn-danger mb-1"
                        onClick={() => actionBtnHandler('REJECT', row?.originalData)}
                        style={{ width: '150px' }}
                      >
                        Reject
                      </button>
                    </>
                  )}

                  {row?.originalData?.status && row?.originalData?.status !== 1 && row?.originalData?.status !== 3 && (
                    <>
                      {row?.originalData?.status !== 4 && (
                        <button
                          className="btn btn-sm btn-warning mb-1"
                          onClick={() => actionBtnHandler('BLOCK', row?.originalData)}
                          style={{ width: '150px' }}
                          disabled={row?.originalData?.status === 4}
                        >
                          Block
                        </button>
                      )}
                      {row?.originalData?.status === 4 && (
                        <button
                          className="btn btn-sm btn-success mb-1"
                          onClick={() => actionBtnHandler('UNBLOCK', row?.originalData)}
                          style={{ width: '150px' }}
                          disabled={row?.originalData?.status !== 4}
                        >
                          Unblock
                        </button>
                      )}
                    </>
                  )}

                  <button
                    className="btn btn-sm btn-danger mb-1"
                    onClick={() => actionBtnHandler('DELETE', row?.originalData)}
                    style={{ width: '150px' }}
                  >
                    Delete
                  </button>
                </>
              )}
            </div>
          ),
        },
        {
          name: 'View Page',
          cell: row => (
            <UncontrolledDropdown className="dropdown d-inline-block">
              <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                <FaEllipsisV style={{ cursor: 'pointer' }} />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <li
                  onClick={() => {
                    viewPageHandler(`VIEW_POST`, row?.originalData);
                  }}
                >
                  <DropdownItem>View Post</DropdownItem>
                </li>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
          width: '100px',
          wrap: true,
        },
      ];
      setColumns(fetchColumns);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            companyName: data?.companyName || `NA`,
            category: data?.category || `NA`,
            dateAndTime: data?.post?.createdAt ? moment(new Date(`${data?.post?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            location: data?.location || `NA`,
            status: data?.post?.status,
            originalData: data?.post || `NA`,
          };
        }),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `ACCEPT`) {
      props.actionHandler({
        postDetails: data,
        actionType: flag,
      });
    } else if (flag === `REJECT`) {
      props.actionHandler({
        postDetails: data,
        actionType: flag,
      });
    } else if (flag === `BLOCK`) {
      props.actionHandler({
        postDetails: data,
        actionType: flag,
      });
    } else if (flag === `UNBLOCK`) {
      props.actionHandler({
        postDetails: data,
        actionType: flag,
      });
    } else if (flag === `DELETE`) {
      props.actionHandler({
        postDetails: data,
        actionType: flag,
      });
    }
  };

  const viewPageHandler = (flag: string, data) => {
    switch (flag) {
      case 'VIEW_POST':
        window.open(`${adibProjectsUrl}/preview/post?postId=${data?.id}&auth=${Constant.CLIENT_TOKEN}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Post Approval List</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.ADIB_DOWNLOAD) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns}
                data={data}
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(String(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1))}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
