import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL, umsUrl } from '../../../../Containers/Config/index.js';
import { CLIENT_LOGIN, CLIENT_LOGIN_SUCCESS, CLIENT_LOGIN_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* clientLogin({ payload }) {
  let headers = payload?.headers?.Authorization
    ? { 'Project-Code': 'ADIB_V2', Authorization: payload?.headers?.Authorization }
    : { 'Project-Code': 'ADMIN_ACCESS', 'X-Requested-By': 'one-admin' };
  try {
    const response = yield call(apiCall, {
      url: `${payload?.headers?.Authorization ? umsUrl : indianProjectsBaseURL}/admin/v1/login`,
      method: 'POST',
      headers: headers,
      data: payload?.data,
    });

    if (response.status === 200) {
      // cogoToast.success(response?.data?.message);
      yield put({
        type: CLIENT_LOGIN_SUCCESS,
        payload: response,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CLIENT_LOGIN_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CLIENT_LOGIN_ERROR, payload: error });
  }
}

export function* watchClientLogin() {
  yield takeEvery(CLIENT_LOGIN, clientLogin);
}

function* clientLoginSaga() {
  yield all([fork(watchClientLogin)]);
}

export default clientLoginSaga;
