import { useEffect, useState } from 'react';

import moment from 'moment';
import cogoToast from 'cogo-toast';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import { formattedWithComma, hasPermission } from '../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';

const logsInfoStyle = {
  cursor: 'pointer',
  position: 'relative',
  top: ' 3px',
  fontSize: '17px',
  left: '3px',
  zIndex: '0',
};

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Display name',
          wrap: true,
          width: '250px',
          cell: row => (
            <span
              className="fw-semibold"
              style={{
                cursor: 'pointer',
                color: '#3577f1',
              }}
              onClick={() => {
                actionBtnHandler(`INFO`, row?.data);
              }}
            >
              {row?.data?.displayName}
            </span>
          ),
        },
        {
          name: 'Category',
          width: '200px',
          wrap: true,
          selector: row => row?.categoryName,
        },
        {
          name: 'Booking Type',
          width: '200px',
          wrap: true,
          selector: row => row?.bookingType,
        },
        {
          name: 'Last Updated',
          width: '220px',
          wrap: true,
          cell: row => (
            <span>
              {row?.data?.updatedAt ? moment(new Date(`${row?.data?.updatedAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA'}

              <span
                style={logsInfoStyle}
                onClick={() => {
                  actionBtnHandler(`LOGS`, row?.data);
                }}
              >
                <i className="ri-information-fill"></i>
              </span>
            </span>
          ),
        },
        {
          name: 'Actions',
          width: '180px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CATEGORY_UPDATE) && (
                <span
                  className="btn btn-sm btn-primary mb-1 d-inline-block w-100"
                  onClick={() => {
                    actionBtnHandler(`UPDATE`, row?.data);
                  }}
                  style={{ width: '100%' }}
                >
                  Update
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data: data || `NA`,
            categoryName: data?.category?.name || `NA`,
            bookingType: data?.bookingType?.name || `NA`,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VIEW`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `INFO`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `LOGS`) {
      if (data?.logs?.length > 0) {
        props.actionHandler({
          details: data,
          actionType: flag,
        });
      } else {
        cogoToast.error('No records found');
      }
    }
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <PreviewCardHeader title={props.title} isShowFilterBtn={props?.isShowFilterBtn} filterBtnHandler={props?.filterBtnHandler} />

          <Card>
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
